import { Box, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import tagsApi from '../../../Network/Tag/index'

import CustomeSelectField from '../../../Components/CustomeSelectField';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "universal-cookie";
import geofenceAPI from '../../../Network/Geofence';
const cookies = new Cookies();

const ArabicLetters = [
  {
    id: "بدون",
    name: "بدون"
  },
  {
    id: "ا",
    name: "ا"
  },
  {
    id: "ب",
    name: "ب"
  },
  {
    id: "ت",
    name: "ت"
  },
  {
    id: "ث",
    name: "ث"
  },
  {
    id: "ج",
    name: "ج"
  },
  {
    id: "ح",
    name: "ح"
  },
  {
    id: "خ",
    name: "خ"
  },
  {
    id: "د",
    name: "د"
  },
  {
    id: "ذ",
    name: "ذ"
  },
  {
    id: "ر",
    name: "ر"
  },
  {
    id: "ز",
    name: "ز"
  },
  {
    id: "س",
    name: "س"
  },
  {
    id: "ش",
    name: "ش"
  },
  {
    id: "ص",
    name: "ص"
  },
  {
    id: "ض",
    name: "ض"
  },
  {
    id: "ط",
    name: "ط"
  },
  {
    id: "ظ",
    name: "ظ"
  },
  {
    id: "ع",
    name: "ع"
  },
  {
    id: "غ",
    name: "غ"
  },
  {
    id: "ف",
    name: "ف"
  },
  {
    id: "ق",
    name: "ق"
  },
  {
    id: "ك",
    name: "ك"
  },
  {
    id: "ل",
    name: "ل"
  },
  {
    id: "م",
    name: "م"
  },
  {
    id: "ن",
    name: "ن"
  },
  {
    id: "ه",
    name: "ه"
  },
  {
    id: "و",
    name: "و"
  },
  {
    id: "ي",
    name: "ي"
  }

]
const EnglishLetters = [
  {
    id: "A",
    name: "A"
  },
  {
    id: "B",
    name: "B"
  },
  {
    id: "D",
    name: "D"
  },
  {
    id: "E",
    name: "E"
  },
  {
    id: "F",
    name: "F"
  },
  {
    id: "H",
    name: "H"
  },
  {
    id: "J",
    name: "J"
  },
  {
    id: "K",
    name: "K"
  },
  {
    id: "L",
    name: "L"
  },
  ,
  {
    id: "M",
    name: "M"
  },
  {
    id: "N",
    name: "N"
  },
  {
    id: "Q",
    name: "Q"
  },
  {
    id: "R",
    name: "R"
  },
  {
    id: "S",
    name: "S"
  },
  {
    id: "T",
    name: "T"
  },
  ,
  {
    id: "W",
    name: "W"
  },
  {
    id: "Z",
    name: "Z"
  }
]

const ArabicProvince = [
  {
    id: "بغداد",
    name: "بغداد"
  },
  {
    id: "بابل",
    name: "بابل"
  },
  {
    id: "واسط",
    name: "واسط"
  },
  {
    id: "كربلاء",
    name: "كربلاء"
  },
  {
    id: "النجف",
    name: "النجف"
  },
  {
    id: "القادسية",
    name: "القادسية"
  },
  {
    id: "المثنى",
    name: "المثنى"
  },
  {
    id: "ذي قار",
    name: "ذي قار"
  },
  {
    id: "ميسان",
    name: "ميسان"
  },
  {
    id: "البصرة",
    name: "البصرة"
  },
  {
    id: "ديالى",
    name: "ديالى"
  },
  {
    id: "الانبار",
    name: "الانبار"
  },
  {
    id: "صلاح الدين",
    name: "صلاح الدين"
  },
  {
    id: "كركوك",
    name: "كركوك"
  },
  {
    id: "نينوى",
    name: "نينوى"
  },
  {
    id: "اربيل",
    name: "اربيل"
  },
  {
    id: "السليمانية",
    name: "السليمانية"
  },
  {
    id: "دهوك",
    name: "دهوك"
  },
  {
    id: "حلبجة",
    name: "حلبجة"
  },
]
const EnglishProvince = [
  {
    id: "11",
    name: "بغداد - 11"
  },
  {
    id: "12",
    name: "نينوى - 12"
  },
  {
    id: "13",
    name: "ميسان - 13"
  },
  {
    id: "14",
    name: "البصرة - 14"
  },
  {
    id: "15",
    name: "الانبار - 15"
  },
  {
    id: "16",
    name: "القادسية - 16"
  },
  {
    id: "17",
    name: "المثنى - 17"
  },
  {
    id: "18",
    name: "بابل - 18"
  },
  {
    id: "19",
    name: "كربلاء - 19"
  },
  {
    id: "20",
    name: "ديالى - 20"
  },
  {
    id: "21",
    name: "السليمانية - 21"
  },
  {
    id: "22",
    name: "اربيل - 22"
  },
  {
    id: "23",
    name: "حلبجة - 23"
  },
  {
    id: "24",
    name: "دهوك - 24"
  },
  {
    id: "25",
    name: "كركوك - 25"
  },
  {
    id: "26",
    name: "صلاح الدين - 26"
  },
  {
    id: "27",
    name: "ذي قار - 27"
  },
  // {
  //   id: "30",
  //   name: "النجف - 30"
  // },
  {
    id: "28",
    name: "النجف - 28"
  },
  {
    id: "31",
    name: "واسط - 31"
  },
]

const plateTypes = [
  // {
  //   id: "خصوصي",
  //   name: "خصوصي"
  // },
  // {
  //   id: "اجرة",
  //   name: "اجرة"
  // },
  {
    id: "حمل",
    name: "حمل"
  },
  // {
  //   id: "دراجة",
  //   name: "دراجة"
  // },
  {
    id: "حكومي",
    name: "حكومي"
  },
  // {
  //   id: "انشائية",
  //   name: "انشائية"
  // },
  // {
  //   id: "زراعية",
  //   name: "زراعية"
  // },
  // {
  //   id: "منظمات",
  //   name: "منظمات"
  // },
  // {
  //   id: "شركات",
  //   name: "شركات"
  // },
  {
    id: "فحص مؤقت",
    name: "فحص مؤقت"
  },
  // {
  //   id: "ادخال كمركي",
  //   name: "ادخال كمركي"
  // }
]

const RenderContent = (props) => {
  const [t] = useTranslation("common");

  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>

          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_number")}
              value={props?.object?.plate_number?.value}
              error={props?.object?.plate_number?.error}
              message={props?.object?.plate_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {
                if (!isNaN(e?.target?.value)) {
                  props?.setObject({
                    ...props?.object,
                    plate_number: {
                      ...props?.object?.plate_number,
                      value: e?.target?.value,
                      error: false,
                      message: []
                    }
                  })
                }

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_number: {
                    ...props?.object?.plate_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>

          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_type")}
              haswidth={true}
              value={props?.object?.arabic_or_english?.value ? props?.object?.arabic_or_english?.value : null}
              list={[
                {
                  id: t('GLOBAL.english'),
                  name:t('GLOBAL.english')
                },
                {
                  id: t('GLOBAL.arabic'),
                  name:t('GLOBAL.arabic')
                },
              ]}
              error={props?.object?.arabic_or_english?.error}
              message={props?.object?.arabic_or_english?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                
                props?.setObject({
                  ...props?.object,
                  arabic_or_english: {
                    ...props?.object?.arabic_or_english,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  arabic_or_english: {
                    ...props?.object?.arabic_or_english,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
           
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px',
            marginBottom:'5px',
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_letter")}
              haswidth={true}
              value={props?.object?.plate_letter_id?.value ? props?.object?.plate_letter_id?.value : null}
              list={props?.object?.arabic_or_english?.value?.id == t('GLOBAL.arabic')?ArabicLetters:EnglishLetters}
              error={props?.object?.plate_letter_id?.error}
              message={props?.object?.plate_letter_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setObject({
                  ...props?.object,
                  plate_letter_id: {
                    ...props?.object?.plate_letter_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_letter_id: {
                    ...props?.object?.plate_letter_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_province")}
              haswidth={true}
              value={props?.object?.plate_province_id?.value ? props?.object?.plate_province_id?.value : null}
              list={props?.object?.arabic_or_english?.value?.id == t('GLOBAL.arabic')?ArabicProvince: EnglishProvince}
              error={props?.object?.plate_province_id?.error}
              message={props?.object?.plate_province_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {
                props?.setObject({
                  ...props?.object,
                  plate_province_id: {
                    ...props?.object?.plate_province_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_province_id: {
                    ...props?.object?.plate_province_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_class")}
              haswidth={true}
              value={props?.object?.plate_type_id?.value ? props?.object?.plate_type_id?.value : null}
              list={plateTypes}
              error={props?.object?.plate_type_id?.error}
              message={props?.object?.plate_type_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setObject({
                  ...props?.object,
                  plate_type_id: {
                    ...props?.object?.plate_type_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_type_id: {
                    ...props?.object?.plate_type_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>


        </Grid>
      </Box>
    </Box>
  );
};

export default RenderContent