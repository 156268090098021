
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next"
import mainUnitObject, {
  checkErrors,
  objectMerge,
  resetErrors,
  resetObject,
} from "../../../Errors/MainUnits/MainUnit";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import mainUnitApi from "../../../Network/MainUnit";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};


const controller = new AbortController();
export default function UnitPlateFormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState(mainUnitObject);

  const navigate = useNavigate();



  const submit = async (refreshFromWialon) => {

    try {
      const jwt = cookies.get("token");

      var headers = {
        jwt: jwt,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      };


      let checkErrorObject = {
        ...unit
      }
      let hasError = false
      let readyToSendObject = {}

      let plate_number = unit?.plate_number?.value?.trim()
      if (unit?.plate_number?.value && unit?.plate_number?.value?.trim()?.length > 0) {
        readyToSendObject["plate_number"] = plate_number?.replace(/\\|\/|\'/g, "");
      }
      else {
        hasError = true
        checkErrorObject['plate_number']['error'] = true
        checkErrorObject['plate_number']['message'] = [t('GLOBAL.mandatory_field')]
      }


      if (unit?.plate_letter_id?.value?.id !== null && unit?.plate_letter_id?.value?.id?.length > 0) {
        readyToSendObject["plate_letter"] = unit?.plate_letter_id?.value?.id;
      }
      else {
        hasError = true
        checkErrorObject['plate_letter_id']['error'] = true
        checkErrorObject['plate_letter_id']['message'] = [t('GLOBAL.mandatory_field')]
      }

      if (unit?.plate_province_id?.value?.id !== null && unit?.plate_province_id?.value?.id?.length > 0) {
        readyToSendObject["plate_province"] = unit?.plate_province_id?.value?.id;
      }
      else {
        hasError = true
        checkErrorObject['plate_province_id']['error'] = true
        checkErrorObject['plate_province_id']['message'] = [t('GLOBAL.mandatory_field')]
      }
      if (unit?.plate_type_id?.value?.id !== null && unit?.plate_type_id?.value?.id?.length > 0) {
        readyToSendObject["plate_type"] = unit?.plate_type_id?.value?.id;
      }
      else {
        hasError = true
        checkErrorObject['plate_type_id']['error'] = true
        checkErrorObject['plate_type_id']['message'] = [t('GLOBAL.mandatory_field')]
      }



      if (hasError) {
        setUnit({
          ...checkErrorObject
        })
        return
      }





      setLoading(true);
      let result = null


      readyToSendObject["id"] = props?.mainUnit?.id;

      result = await mainUnitApi.editPlateMainUnitTemp({
        data: readyToSendObject,

        id: props?.mainUnit?.id,
        headers: {
          ...headers,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });


      setLoading(false);


      if (result?.status && result?.data?.status) {

        setLoading(false);

        toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
        clearForm();
        props.loadData();
        props.setOpenForm(false);
      }
      else if (result?.data?.data?.message?.text == "unauthorized to complete") {
        navigate("/redirect");
      }

      else {

        toast.success(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
      }

      setLoading(false);
    } catch (error) {

      setLoading(false);
      props.setOpenForm(false);
      toast.error(error.message);
    }

  };

  const clearForm = () => {


    setUnit({
      plate_type_id: {
        value: "",
        error: false,
        message: "",
        required: false
      },
      plate_province_id: {
        value: "",
        error: false,
        message: "",
        required: false
      },
      plate_letter_id: {
        value: "",
        error: false,
        message: "",
        required: false
      }, plate_number: {
        value: "",
        error: false,
        message: "",
        required: false
      },
      arabic_or_english: {
        value: {
          id: t('GLOBAL.arabic'),
          name: t('GLOBAL.arabic')
        },
        error: false,
        message: "",
        required: false
      },
    })
  };
  function isAllEnglishLetters(value) {
    const regex = /^[A-Za-z]+$/;
    
    return regex.test(value);
  }
  useEffect(() => {
    if (!props?.mainUnit) {
      clearForm();

    } else {

      setUnit({
        ...props?.mainUnit,
        id: props?.mainUnit?.id,

        plate_number: {
          value: props?.mainUnit?.plate_number,
          error: false,
          message: "",
          required: false
        },
        arabic_or_english: {
          value: {
            id: isAllEnglishLetters(props?.mainUnit?.plate_letter)?t('GLOBAL.english'):t('GLOBAL.arabic'),
            name: isAllEnglishLetters(props?.mainUnit?.plate_letter)?t('GLOBAL.english'):t('GLOBAL.arabic')
          },
          error: false,
          message: "",
          required: false
        },
        plate_type_id: {
          value: {
            id: props?.mainUnit?.plate_type,
            name: props?.mainUnit?.plate_type
          },
          error: false,
          message: "",
          required: false
        },
        plate_province_id: {
          value: {
            id: props?.mainUnit?.plate_province,
            name: props?.mainUnit?.plate_province,
          },
          error: false,
          message: "",
          required: false
        },
        plate_letter_id: {
          value: {
            id: props?.mainUnit?.plate_letter,
            name: props?.mainUnit?.plate_letter
          },
          error: false,
          message: "",
          required: false
        }
      })
    }
  }, [props?.openForm, props?.mainUnit]);


  
  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.updateOrEdit == "update" ?t("UNITS.update_plate_number"): t("UNITS.edit_plate_number")
      }
      isFullScreen={false}
      content={
        <RenderContent
          object={unit}
          setObject={(value) => {
            setUnit(value)
          }}
          open={props.openForm}
          setOpen={props.setOpenForm}
          isFullScreen={false}

        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
